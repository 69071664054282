import React from 'react';
import PropTypes from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { ConsumerHomepage } from './ConsumerHomepage';

export const ConsumerHomepageContainer = ({ slug, thdSEOBotDetection }) => {
  return (
    <ErrorBoundary name="my-homepage-content">
      <QueryProvider cacheKey="consumer-homepage">
        <ConsumerHomepage slug={slug} thdSEOBotDetection={thdSEOBotDetection} />
      </QueryProvider>
    </ErrorBoundary>
  );
};

ConsumerHomepageContainer.propTypes = {
  slug: PropTypes.string,
  thdSEOBotDetection: PropTypes.bool
};

ConsumerHomepageContainer.defaultProps = {
  slug: undefined,
  thdSEOBotDetection: false
};

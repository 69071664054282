export const componentOptions = {
  OnlineWorkshops: {
    sizes: {
      minWidthMedium: {
        min: 6,
        max: 12,
      },
      minWidthLarge: {
        min: 4,
        max: 4,
      },
    },
  },
  'Top Savings for You': {
    sizes: {
      minWidthMedium: {
        min: 12,
        max: 12,
      },
      minWidthLarge: {
        min: 12,
        max: 12,
      },
    },
  },
  SpecialBuyOfTheDay: {
    sizes: {
      minWidthMedium: {
        min: 6,
        max: 12,
      },
      minWidthLarge: {
        min: 4,
        max: 4,
      },
    },
  },
  'Todays Recommendations for You': {
    sizes: {
      minWidthMedium: {
        min: 12,
        max: 12,
      },
      minWidthLarge: {
        min: 12,
        max: 12,
      },
    },
  },
  'Sponsored Products': {
    sizes: {
      minWidthMedium: {
        min: 12,
        max: 12,
      },
      minWidthLarge: {
        min: 12,
        max: 12,
      },
    },
  },
  'Buy It Again': {
    sizes: {
      minWidthMedium: {
        min: 6,
        max: 12,
      },
      minWidthLarge: {
        min: 8,
        max: 12,
      },
    },
  },
  HomeDecorCard: {
    sizes: {
      minWidthMedium: {
        min: 12,
        max: 12,
      },
      minWidthLarge: {
        min: 12,
        max: 12,
      }
    }
  }
};